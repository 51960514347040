import { generateSalaryString } from "~/components/salaryString/salaryStringFormat";
import { useNavigate } from "@remix-run/react";
import { useWindowWidth } from "~/components/windowWidth";
interface Props {
  activeBlockId: number;
  setActiveBlockId: (id: number) => void;
  setActiveJobData: any;
  jobDetails: any;
}

export default function NewCard({
  activeBlockId,
  setActiveBlockId,
  setActiveJobData,
  jobDetails,
}: Props) {

  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  const formatText = (text = "") =>
    text
      .trim()
      .toLowerCase()
      .replace(/[!@#$%^&*(),-/]/g, "")
      .replace(/\s+/g, "-");

  const handleBlockClick = () => {

    if(!isMobile){
      setActiveBlockId(jobDetails.id);
      setActiveJobData(jobDetails);
    }else{
      navigate(`/jobs/${encodeURIComponent(
      formatText(jobDetails.clientJobDescriptionName)
    )}?id=${jobDetails.clientJobDescriptionId}`)
    }
  };

  return (
    <div
      onClick={handleBlockClick}
      className={`${
        activeBlockId === jobDetails.id
          ? "bg-[#273580] text-white"
          : "bg-white text-[#273580]"
      } p-4 border border-gray-400 rounded-2xl m-3`}
      style={{cursor: "pointer"}}
    >
      <h1 className="text-xl font-bold mb-3">
        <div className="w-1/1 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 24 24"
          >
            <path
              d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            ></path>
          </svg>
          <span className="font-extralight pl-[5px]">
            {jobDetails.categoryName}
          </span>
        </div>
      </h1>
      <div className="flex mb-2 justify-between gap-4">
        <div className="w-1/2 flex items-center">
          <svg
            width={13}
            height={15}
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.17399 14.832C6.19538 14.8444 6.2122 14.854 6.22411 14.8607L6.24389 14.8717C6.40175 14.9584 6.59758 14.9577 6.7556 14.872L6.77589 14.8607C6.7878 14.854 6.80462 14.8444 6.82601 14.832C6.8688 14.8072 6.92994 14.771 7.00681 14.7237C7.1605 14.6291 7.37745 14.4895 7.6366 14.3065C8.15402 13.9409 8.84441 13.3986 9.5363 12.6902C10.9134 11.2803 12.3438 9.16186 12.3438 6.4375C12.3438 3.21009 9.72741 0.59375 6.5 0.59375C3.27259 0.59375 0.65625 3.21009 0.65625 6.4375C0.65625 9.16186 2.0866 11.2803 3.4637 12.6902C4.1556 13.3986 4.84598 13.9409 5.3634 14.3065C5.62255 14.4895 5.8395 14.6291 5.99319 14.7237C6.07006 14.771 6.1312 14.8072 6.17399 14.832ZM6.5 8.5625C7.6736 8.5625 8.625 7.6111 8.625 6.4375C8.625 5.2639 7.6736 4.3125 6.5 4.3125C5.3264 4.3125 4.375 5.2639 4.375 6.4375C4.375 7.6111 5.3264 8.5625 6.5 8.5625Z"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
          </svg>
          <span className="font-extralight pl-[5px]">
            {jobDetails.locationName}
          </span>
        </div>
        <div className="w-1/2 flex items-center">
          <svg
            width="17"
            height="17"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
              d="M459.26,96,328,225.7V96H293.475L168,223.267V16H16V496H496V96ZM464,464H48V48h88V264h36.778L296,139.018V264h38.764L464,136.3Z"
            />
            <polygon
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
              points="136 328 136 336 168 336 168 328 168 304 136 304 136 328"
            />
            <rect
              width="32"
              height="32"
              x="136"
              y="376"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
            <polygon
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
              points="216 328 216 336 248 336 248 328 248 304 216 304 216 328"
            />
            <rect
              width="32"
              height="32"
              x="216"
              y="376"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
            <polygon
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
              points="296 328 296 336 328 336 328 328 328 304 296 304 296 328"
            />
            <rect
              width="32"
              height="32"
              x="296"
              y="376"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
            <rect
              width="32"
              height="32"
              x="376"
              y="304"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
            <rect
              width="32"
              height="32"
              x="376"
              y="376"
              fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
            />
          </svg>
          <span className="font-extralight pl-[5px]">
            {jobDetails.industry}
          </span>
        </div>
      </div>
      <div className="flex mb-2 justify-between gap-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 512 512"
            >
              <path
                fill={activeBlockId === jobDetails.id ? "white" : "#031A38"}
                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm261.5-96c-16.8 0-30.4 13.7-30.3 30.5l.2 33.5 40.5 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-40.3 0 .1 9.7c.1 18.6-3.1 37-9.6 54.3L328 320c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-8.4 0-16.1-4.4-20.5-11.5s-4.7-16-.8-23.5l9.6-18.6c7.7-14.9 11.6-31.5 11.5-48.3l-.1-10.1-7.7 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l7.5 0-.2-33.1c-.3-43.5 34.8-78.9 78.3-78.9 10 0 19.8 1.9 29.1 5.6l30.3 12.1c12.3 4.9 18.3 18.9 13.4 31.2s-18.9 18.3-31.2 13.4l-30.3-12.1c-3.6-1.4-7.4-2.2-11.3-2.2z"
              ></path>
            </svg>
          </div>

          <span className="font-extralight pl-[5px]">
            {generateSalaryString(jobDetails)}
          </span>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-3 lg:flex-row lg:justify-start">
        <div className="w-full lg:w-auto flex lg:flex-grow-0">
          <a
            href={jobDetails.jobUrl}
            className="bg-white border border-gray-300 text-[#273580] p-2 rounded-full hover:bg-[#1bafe3] hover:text-white shadow px-4 relative z-10 whitespace-nowrap text-center block lg:inline-block"
          >
            Apply Now
          </a>
        </div>
        {activeBlockId !== jobDetails.id && (
          <div className="w-full lg:w-auto flex lg:flex-grow-0">
            <a className="bg-white border border-gray-300 text-[#273580] p-2 rounded-full hover:bg-[#1bafe3] hover:text-white shadow px-4 relative z-10 whitespace-nowrap text-center block lg:inline-block cursor-pointer	">
              Find out more
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
